import { Col } from 'antd'
import SelfServeSummaryInfoCard from 'components/card/SelfServeSummaryInfoCard'
import CoreCollapse from 'components/collapse/CoreCollapse'
import useSummaryForm from 'hooks/projects/useSummaryForm'
import { SelfServeInstallationDetailSchema } from 'models/self-serve-commissioning'

export interface SummaryFormProps {
  currentValues: SelfServeInstallationDetailSchema
  goToStep: (step: number) => void
}

const SummaryForm: React.FC<SummaryFormProps> = ({ currentValues, goToStep }) => {
  const { networkData, panelData, chargerData, onEdit, nonEditable, installerProfileData } = useSummaryForm({
    currentValues,
    goToStep,
  })

  return (
    <Col>
      <p className="heading-03-bold">{'Summary'}</p>
      <CoreCollapse title={'Installer Profile'}>
        <SelfServeSummaryInfoCard onPressEdit={undefined} data={installerProfileData} />
      </CoreCollapse>

      <CoreCollapse title={'Network'}>
        <SelfServeSummaryInfoCard onPressEdit={nonEditable ? undefined : onEdit('network')} data={networkData} />
      </CoreCollapse>

      <CoreCollapse title={'Panel'}>
        {currentValues?.panels?.map((panel, index) => (
          <SelfServeSummaryInfoCard
            onPressEdit={nonEditable ? undefined : onEdit('panel')}
            data={panelData(panel, index)}
          />
        ))}
      </CoreCollapse>

      <CoreCollapse title={'Chargers'}>
        {currentValues?.chargers?.map((charger, index) => (
          <SelfServeSummaryInfoCard
            onPressEdit={index === 0 && !nonEditable ? onEdit('charger') : undefined}
            hideBorderRadius={index !== (currentValues?.chargers?.length || 1) - 1}
            data={chargerData(charger, index)}
          />
        ))}
      </CoreCollapse>
    </Col>
  )
}

export default SummaryForm
