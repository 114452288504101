import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { Row } from 'antd'
import Upload, { RcFile, UploadChangeParam, UploadProps } from 'antd/lib/upload'
import { UploadFile } from 'antd/lib/upload/interface'
import CoreModal from 'components/modal/CoreModal'
import { useNotifications } from 'hooks/useNotifications'
import { SelfServeImageType, SelfServePhotoSchema } from 'models/self-serve-commissioning'
import { useState } from 'react'

interface UploadImageProps {
  loadedImages: SelfServePhotoSchema[]
  setLoadedImages: (updatedImages: SelfServePhotoSchema[]) => void
  uploadFunction: (fileData: FormData) => Promise<any>
  type?: SelfServeImageType
  maxImages: number
}

const UploadImage: React.FC<UploadImageProps> = ({
  loadedImages,
  setLoadedImages,
  uploadFunction,
  type,
  maxImages,
}) => {
  const [loading, setLoading] = useState(false)
  const { openErrorNotification } = useNotifications()
  const imageSizeMB = 10 //10MB

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Photo</div>
    </button>
  )

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      openErrorNotification('You can only upload JPG/PNG file!')
    }
    const isLtM = file.size / 1024 / 1024 < imageSizeMB
    if (!isLtM) {
      openErrorNotification(`Image must smaller than ${imageSizeMB}MB!`)
    }
    return isJpgOrPng && isLtM
  }

  const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    if (info.file?.status === 'removed') {
      setLoadedImages(loadedImages.filter(({ id }) => id !== info.file.uid))
    }
  }

  const handleCustomRequest = async ({ file }: any) => {
    const formData = new FormData()
    formData.append('file', await normalizeImage(file))
    setLoading(true)
    try {
      const { data } = await uploadFunction(formData)
      let dataWithType = { ...data, type }
      let updatedImages = [...loadedImages, dataWithType]
      setLoadedImages(updatedImages)
    } catch (error) {
      openErrorNotification((error as any)?.cause?.description || 'Something went wrong')
    } finally {
      setLoading(false)
    }
  }

  const normalizeImage = async (file: File): Promise<File> => {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.onload = (event) => {
        const img = new Image()
        img.onload = () => {
          const canvas = document.createElement('canvas')
          const ctx = canvas.getContext('2d')

          canvas.width = img.width
          canvas.height = img.height

          ctx?.drawImage(img, 0, 0, canvas.width, canvas.height)

          canvas.toBlob((blob) => {
            const normalizedFile = new File([blob!], file.name, { type: file.type })
            resolve(normalizedFile)
          }, file.type)
        }
        img.src = event.target?.result as string
      }
      reader.readAsDataURL(file)
    })
  }

  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')

  const handleCancel = () => setPreviewOpen(false)

  const handlePreview = async (file: UploadFile) => {
    const previewableImage = loadedImages.find(({ id }) => id === file.uid)
    setPreviewImage(previewableImage?.url || '')
    setPreviewOpen(true)
  }

  return (
    <Row gutter={[10, 10]}>
      <CoreModal
        saveTitle=""
        onFinish={() => {}}
        isModalOpen={previewOpen}
        closable
        title={'Preview'}
        footer={null}
        onClose={handleCancel}
        centered
      >
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </CoreModal>
      <Upload
        name="avatar"
        listType="picture-card"
        beforeUpload={beforeUpload}
        onChange={handleChange}
        accept=".jpg,.jpeg,.png"
        customRequest={handleCustomRequest}
        className="one-third-dimension"
        //capture="environment"
        onPreview={handlePreview}
        fileList={loadedImages.map((img) => ({
          uid: img.id,
          name: img.url,
          url: img.urlThumb,
          status: 'done',
        }))}
      >
        {loadedImages.length < maxImages && uploadButton}
      </Upload>
    </Row>
  )
}

export default UploadImage
