import { Col } from 'antd'

const CompletedForm: React.FC = () => {
  return (
    <Col>
      <p className="heading-03-bold">{'Completed'}</p>
      <p className="heading-06-regular">
        {'The chargers are now properly installed. SWTCH will work with the property to activate these chargers.'}
      </p>
      <p className="heading-06-regular">{'Ensure that the chargers remain powered ON.'}</p>
      <p className="heading-06-regular">{'Thanks for installing with SWTCH!'}</p>
    </Col>
  )
}

export default CompletedForm
