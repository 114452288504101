import { Button, Col, Row } from 'antd'
import { editSVG as EditSvg } from 'assets/svg/edit'
import CoreModal from 'components/modal/CoreModal'
import { SelfServePhotoSchema } from 'models/self-serve-commissioning'
import { useState } from 'react'
import { theme } from 'theme'

export interface SelfServeSummaryInfoDataProps {
  header?: string
  title: string
  body?: string
  photos?: SelfServePhotoSchema[]
  bodyType?: 'image'
}

interface SelfServeSummaryInfoCardProps {
  onPressEdit?: () => void
  hideBorderRadius?: boolean
  data: SelfServeSummaryInfoDataProps[]
}

const SelfServeSummaryInfoCard: React.FC<SelfServeSummaryInfoCardProps> = ({
  onPressEdit,
  hideBorderRadius = false,
  data,
}) => {
  const [previewImage, setPreviewImage] = useState('')
  const [previewOpen, setPreviewOpen] = useState(false)

  const handleCancel = () => {
    setPreviewImage('')
    setPreviewOpen(false)
  }

  const handlePreview = (url: string) => {
    setPreviewImage(url)
    setPreviewOpen(true)
  }
  return (
    <>
      <CoreModal
        saveTitle=""
        onFinish={() => {}}
        isModalOpen={previewOpen}
        closable
        title={'Preview'}
        footer={null}
        onClose={handleCancel}
        centered
      >
        <img alt="preview" style={{ width: '100%' }} src={previewImage} />
      </CoreModal>
      <Col
        style={{
          backgroundColor: theme.colors.grayBackground,
          padding: 16,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          borderBottomLeftRadius: hideBorderRadius ? 0 : 15,
          borderBottomRightRadius: hideBorderRadius ? 0 : 15,
        }}
      >
        {onPressEdit && (
          <Row justify="end">
            <Button type="text" style={{ padding: 0 }} onClick={onPressEdit}>
              <Row align="middle">
                <EditSvg />
                <span className="regular-cap-02" style={{ color: theme.colors.primary, marginLeft: 5 }}>
                  {'Edit'}
                </span>
              </Row>
            </Button>
          </Row>
        )}

        {data.map(({ header, title, body, bodyType, photos }) => (
          <>
            {header && <span className="heading-06-regular">{header}</span>}
            <Row style={{ marginTop: 12 }}>
              <Col span={bodyType === 'image' ? 24 : 12}>
                <span className="paragraph-01-regular" style={{ color: theme.colors.darkGray }}>
                  {title}
                </span>
              </Col>
              {bodyType === 'image' ? (
                <Col span={24}>
                  <Row gutter={[10, 10]} justify="end">
                    {photos?.map(({ urlThumb, url }) => (
                      <Col
                        span={6}
                        style={{
                          overflow: 'hidden',
                          cursor: 'pointer',
                        }}
                        onClick={() => handlePreview(url)}
                      >
                        <img
                          alt={urlThumb}
                          src={urlThumb}
                          style={{
                            width: '100%',
                            aspectRatio: '1 / 1',
                            objectFit: 'cover',
                            border: `1px solid ${theme.colors.grayScale}`,
                            padding: 5,
                            borderRadius: '4px',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                          }}
                        />
                      </Col>
                    ))}
                  </Row>
                </Col>
              ) : (
                <Col span="12" style={{ textAlign: 'end' }}>
                  <span className="paragraph-01-regular">{body}</span>
                </Col>
              )}
            </Row>
          </>
        ))}
      </Col>
    </>
  )
}

export default SelfServeSummaryInfoCard
